<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <template
        v-if="showTableNameInHeader"
        #header
      >
        <h4
          class="d-flex align-items-center mb-0"
        >
          Family Info
        </h4>

        <feather-icon
          v-if="useCollapse"
          :aria-expanded="showOverviewTable ? 'true' : 'false'"
          :icon="showOverviewTable ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="20"
          class="align-middle text-body"
          aria-controls="collapse-app-tasks-table"
          @click="showOverviewTable = !showOverviewTable"
        />
      </template>

      <b-collapse
        id="collapse-app-tasks-table"
        v-model="showOverviewTable"
      >
        <div
          v-if="showTableHeader"
          class="table-header mx-2 mb-2"
          :class="showTableNameInHeader ? '' : 'mt-2'"
        >

          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="mr-1 mb-0">Show</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :value="perPage"
                :clearable="false"
                :reduce="option => option.value"
                class="per-page-selector d-inline-block ml-50 mr-1"
                @input="updatePerPage"
              />
            </b-col>
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <label class="mr-1 mb-0">Search</label>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search"
                  class="w-25"
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refApplicationTasksListTable"
          :items="fetchFamilyAttributesList"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          sticky-header="75vh"
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-sticky-head"
        >

          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <!-- Column: value -->
          <template #cell(value)="data">
            <div
              v-if="!editMode || manageableFieldId !== data.item.id"
              class="d-flex align-items-center text-break"
            >
              <span v-if="isArray(data.value)">{{ data.value | toString }}</span>
              <span v-else-if="isBase64(data.value)">
                <img
                  :src="data.value"
                  alt="signature"
                >
              </span>
              <span>{{ data.value || 'No value' }}</span>
            </div>

            <div
              v-if="editMode && manageableFieldId === data.item.id"
              class="d-flex align-items-center"
            >
              <b-form-input
                v-if="!isBase64(data.value) && !isArray(data.value)"
                v-model="manageableFieldValue"
                :value="manageableFieldValue"
                size="sm"
              />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div v-if="canUpdateFamilyAttribute">
              <div
                v-if="editMode && manageableFieldId === data.item.id"
              >
                <span
                  class="text-primary cursor-pointer"
                  @click="saveFieldChanges"
                >
                  <feather-icon
                    size="16"
                    icon="CheckIcon"
                    class="ml-1"
                  /> Apply
                </span>

                <span
                  class="text-danger cursor-pointer"
                  @click="clearData"
                >
                  <feather-icon
                    size="16"
                    icon="XIcon"
                    class="ml-1"
                  /> Cancel
                </span>
              </div>

              <span
                v-else
                class="text-primary cursor-pointer"
                @click="editFamilyAttribute(data.item)"
              >
                <feather-icon
                  size="16"
                  icon="Edit2Icon"
                  class="ml-1"
                /> Edit
              </span>
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-collapse>

    </b-card>

    <confirmation-modal
      v-if="manageableFieldId"
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-family-attribute'"
      :title="'Are you sure'"
      :message="`Are you sure you want to delete this family attribute(${manageableFieldName})?`"
      :is-loading="isLoading"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteItem"
    />
  </div>
</template>

<script>
import { filters } from '@core/mixins/filters'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BCollapse,
} from 'bootstrap-vue'

import Vue from 'vue'
import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { appFamilyAttributesList } from '@/store/modules/app-family-attributes-list'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

let debounceTimeout = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'FamilyAttributesList',
  components: {
    ConfirmationModal,
    BCollapse,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    TableSpinner,

    vSelect,
  },
  filters: {
    toString(val) {
      return val.map(o => {
        if (typeof o === 'object') {
          return o.name
        }
        return o
      }).join(', ')
    },
  },
  mixins: [filters],
  props: {
    showTableNameInHeader: {
      type: Boolean,
      default: false,
    },
    showTableHeader: {
      type: Boolean,
      default: true,
    },
    useCollapse: {
      type: Boolean,
      default: false,
    },
    filterName: {
      type: String,
      default: null,
    },
    filterValue: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      confirmationModalToggle: false,
      showOverviewTable: true,
      editMode: false,
      permissionSubjects,
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    canDeleteFamilyAttribute() {
      return this.$can('update', permissionSubjects.User)
    },
    canUpdateFamilyAttribute() {
      return this.$can('update', permissionSubjects.User)
    },
    tableColumns() {
      return appFamilyAttributesList.getters.getTableColumns
    },
    totalItems() {
      return appFamilyAttributesList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appFamilyAttributesList.getters.getCurrentPage
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appFamilyAttributesList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appFamilyAttributesList.getters.getSearchQuery
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appFamilyAttributesList.getters.getSortBy
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_SORT_BY(val)
      },
    },
    perPage: {
      get() {
        return appFamilyAttributesList.getters.getPerPage
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_PER_PAGE(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appFamilyAttributesList.getters.getIsSortDirDesc
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    isLoading() {
      return appFamilyAttributesList.getters.getIsLoading
    },
    dataMeta() {
      return appFamilyAttributesList.getters.getDataMeta
    },
    manageableFieldName: {
      get() {
        return appFamilyAttributesList.getters.getManageableFieldName
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_MANAGEABLE_FIELD_NAME(val)
      },
    },
    manageableFieldId: {
      get() {
        return appFamilyAttributesList.getters.getManageableFieldId
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_MANAGEABLE_FIELD_ID(val)
      },
    },
    manageableFieldValue: {
      get() {
        return appFamilyAttributesList.getters.getManageableFieldValue
      },
      set(val) {
        appFamilyAttributesList.mutations.SET_MANAGEABLE_FIELD_VALUE(val)
      },
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }

      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  async created() {
    this.setFilterParams()

    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    isArray(val) {
      return Array.isArray(val)
    },
    isBase64(val) {
      return (typeof (val) === 'string' && val.includes('data:image'))
    },
    updatePerPage(val) {
      appFamilyAttributesList.mutations.SET_PER_PAGE(val)
    },
    clearFilters() {
      appFamilyAttributesList.mutations.CLEAR_FILTERS()
    },
    setFilterParams() {
      appFamilyAttributesList.mutations.SET_FILTER_PARAMS()
    },
    fetchFamilyAttributesList() {
      return appFamilyAttributesList.actions.fetchFamilyAttributesList({
        name: this.filterName,
        value: this.filterValue,
      })
    },
    updateTable() {
      this.$refs.refApplicationTasksListTable.refresh()
    },
    openDeleteConfirmation(id, name) {
      this.manageableFieldId = id
      this.manageableFieldName = name
      this.$refs['confirmation-delete-family-attribute'].show()
    },
    async deleteItem() {
      this.isLoading = true
      await appFamilyAttributesList.actions.deleteFamilyAttribute(this.manageableFieldId)
      this.isLoading = false
      this.$refs['confirmation-delete-family-attribute'].hide()
      await this.clearData()
      await this.updateTable()
    },
    async clearData() {
      this.manageableFieldId = null
      this.manageableFieldName = null
      this.manageableFieldValue = null
      this.editMode = false
    },
    async editFamilyAttribute(field) {
      this.manageableFieldId = field.id
      this.manageableFieldValue = field.value
      this.editMode = true
    },
    async saveFieldChanges() {
      this.isLoading = true
      await appFamilyAttributesList.actions.updateFamilyAttribute()
      this.isLoading = false

      await this.clearData()
      await this.updateTable()
    },
  },
})
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
